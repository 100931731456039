<template>
    <v-dialog
        v-model="display"
        persistent
        content-class="content-list"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        width="800"
    >
        <v-card class="content-list-dialog no-selection overflow-hidden d-flex flex-column">
            <div class="dialog-error error px-4 flex-grow-1" :class="{ active: showError }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text small--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="showError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3 flex-grow-1">
                <p class="text-center mb-0">
                    <b>Create - {{ type }}</b>
                </p>
            </div>
            <v-container v-if="items" class="container-form">
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="model.title" class="pa-0 ma-0" type="text" label="Title"></v-text-field>
                    </v-col>
                    <template v-if="type == 'Category'">
                        <v-checkbox v-model="requireDescription" label="Require Description?"> </v-checkbox>
                    </template>

                    <template v-if="type == 'Category' || type == 'Award' || type == 'Room'">
                        <image-upload
                            :readonly="false"
                            full-width
                            :create="true"
                            v-model="image"
                            :sizeRestriction="false"
                            :allowPNG="true"
                        ></image-upload>
                    </template>

                    <template v-if="type == 'Room'">
                        <v-col cols="4">
                            <v-btn @click="openScribe">Select Scribe</v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-btn @click="openJudge">Select Judges</v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="chairs"
                                type="number"
                                class="field"
                                dense
                                filled
                                solo
                                flat
                                label="Chairs"
                                required
                                hint="How many judges will be in this room?"
                                persistent-hint
                            ></v-text-field>
                        </v-col>
                    </template>
                    <template v-if="type == 'Entry Bundle'">
                        <v-col cols="12">
                            <v-text-field
                                v-model="displayTitle"
                                class="pa-0 ma-0"
                                type="text"
                                label="Bundle Display Title"
                                hint="This is what the entrants will see."
                                persistent-hint
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="entries"
                                type="number"
                                class="field"
                                dense
                                filled
                                solo
                                flat
                                label="Amount of entries"
                                required
                                hint="How many entries will this bundle buy?"
                                persistent-hint
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                :prefix="`$${$app.currency}`"
                                v-model="price"
                                type="number"
                                class="field"
                                dense
                                filled
                                solo
                                flat
                                label="Price"
                                required
                                hint="What is the price of this bundle?"
                                persistent-hint
                            ></v-text-field>
                        </v-col>
                        <!-- Purchase -->
                        <v-col cols="6">
                            <date-picker
                                key="BundleStart"
                                :range="false"
                                class="field"
                                v-model="startDate"
                                :selected="optionsBundleStart"
                                hide-details
                            ></date-picker>
                            <p class="hint-text">Bundle Start Date</p>
                        </v-col>

                        <v-col cols="6">
                            <date-picker
                                key="BundleEnd"
                                :range="false"
                                class="field"
                                v-model="endDate"
                                :selected="optionsBundleEnd"
                                hide-details
                            ></date-picker>
                            <p class="hint-text">Bundle end date</p>
                        </v-col>
                    </template>
                    <template v-if="type == 'Medium'">
                        <v-col cols="6">
                            <v-text-field
                                :prefix="`$${$app.currency}`"
                                v-model="price"
                                type="number"
                                class="field"
                                dense
                                filled
                                solo
                                flat
                                label="Price"
                                required
                                hint="How much will this cost?"
                                persistent-hint
                            ></v-text-field>
                        </v-col>
                    </template>
                    <v-col cols="12">
                        <v-textarea label="Description" rows="1" auto-grow v-model="model.body"></v-textarea>
                    </v-col>
                </v-row>
            </v-container>

            <v-card-actions class="actions flex-grow-1 light pa-3">
                <v-btn :color="cancelColor" uppercase @click="cancel">
                    <span class="dark--text">{{ cancelLabel }}</span>
                </v-btn>
                <v-btn :disabled="isDisabled" :color="actionColor" uppercase @click="confirm">{{ actionLabel }}</v-btn>
            </v-card-actions>

            <v-overlay color="light" opacity="0.85" class="p-absolute zindex-30" v-if="loading">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
        <content-list-dialog
            v-model="selectedScribe"
            ref="contentScribeDialog"
            title="Select Scribe"
            type="persona"
            :singleSelect="true"
            :headers="headers"
            @confirm="confirmScribeSelect"
        ></content-list-dialog>
        <content-list-dialog
            v-model="selectedJudges"
            ref="contentJudgeDialog"
            title="Select Judges"
            type="persona"
            :headers="headers"
            @confirm="confirmJudgeSelect"
        ></content-list-dialog>
    </v-dialog>
</template>

<script>
import ImageUpload from "@/components/global/image-upload.vue";
import DatePicker from "@/components/form-field/date-picker.vue";
// Packages
import _ from "lodash";
import ContentListDialog from "./content-list-dialog.vue";

export default {
    name: "article-create-dialog",

    props: {
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        type: {
            type: String,
            default() {
                return "article";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },

        categoryType: {
            type: String,
            default() {
                return "primary";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "grey";
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },

    components: {
        ContentListDialog,
        DatePicker,
        ImageUpload,
    },

    data() {
        return {
            //COMMON ALL
            model: { title: null, body: null, data: null },
            //Rooms
            chairs: 0,
            selectedScribe: null,
            selectedJudges: null,
            headers: [
                {
                    text: "Name",
                    value: "title",
                    type: "text-cell",
                },
                {
                    text: "Email Address",
                    value: "username",
                    type: "text-cell",
                },
            ],
            //For Category
            requireDescription: true,
            //For Entry Bundle
            price: 1, //in dollars
            priceFixed: null, // in cents
            entries: 1,
            displayTitle: null,
            startDate: null,
            endDate: null,
            optionsBundleStart: {
                minimum: 0,
                maximum: 1,
            },
            optionsBundleEnd: {
                minimum: 0,
                maximum: 1,
            },
            //Shared
            image: null,
            //Other

            display: false,
            loading: false,
            showError: false,
            message: "",
            errorMessage: "",
            index: null,
            key: null,
            items: [],
            rules: {
                max: (v) => {
                    if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
                    return "Number has to be between 0 and 100";
                },
            },
        };
    },

    computed: {
        isDisabled() {
            if (!this.model.title) return true;
            if (!this.model.body) return true;
            //Award
            if (this.type == "Award") {
                if (!this.image) return true;
            }
            //Category
            if (this.type == "Category") {
                if (!this.image) return true;
            }
            //Bundle
            if (this.type == "Entry Bundle") {
                if (!this.entries) return true;
                if (!this.price) return true;
                if (!this.startDate) return true;
                if (!this.endDate) return true;
                if (!this.displayTitle) return true;
            }

            //Room
            if (this.type == "Room") {
                if (!this.image) return true;
            }
            return false;
        },
    },

    methods: {
        confirmScribeSelect(scribe) {
            this.selectedScribe = scribe[0];
            this.$refs.contentScribeDialog.close();
        },
        confirmJudgeSelect(judges) {
            this.selectedJudges = judges;
            this.$refs.contentJudgeDialog.close();
        },
        openScribe() {
            this.$refs.contentScribeDialog.open();
        },
        openJudge() {
            this.$refs.contentJudgeDialog.open();
        },
        populateImage(item) {
            //Get the image
            const image = this.$fluro.asset.imageUrl(item);
            return image;
        },
        async init() {
            this.showError = false;
            this.loading = true;
            //Reset
            this.image = null;
            this.model = { title: null, realms: [this.$app.liveRealm], body: null, data: null };
            this.loading = false;
        },
        async open() {
            this.init();
            console.log(this.value);
            this.display = true;
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        appendData() {
            //Append Data depending on definition
            if (this.type == "Category") {
                this.model.data = {
                    image: this.image,
                    type: this.categoryType || "digital",
                    requireDescription: this.requireDescription,
                };
            } else if (this.type == "Entry Bundle") {
                this.model.data = {
                    entries: this.entries,
                    price: this.priceFixed,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    displayTitle: this.displayTitle,
                };
            } else if (this.type == "Award") {
                this.model.definition = "award";
            } else if (this.type == "Room") {
                this.model.data = {
                    competition: this.$route.params.id,
                    selectableJudges: this.selectedJudges,
                    limit: this.chairs,
                    image: this.image,
                    scribe: this.selectedScribe,
                };
            } else if (this.type == "Medium") {
                this.model.data = {
                    price: this.priceFixed,
                };
            }
        },
        confirm() {
            this.loading = true;
            this.appendData();

            let _id = null;
            //Due to an award being an image it is already uploaded, just need to modify its definition
            if (this.type == "Award") {
                _id = this.image._id;
                this.$emit("confirm", this.model, this.type, _id);
            } else {
                this.$emit("confirm", this.model, this.type);
            }
            this.loading = false;
        },
        cancel() {
            this.$emit("cancel");
            this.close();
        },
        close() {
            this.display = false;
            this.index = null;
            this.key = null;
            this.selected = [];
        },
    },
    watch: {
        price: {
            immediate: true,
            handler(v) {
                this.priceFixed = v * 100;
            },
        },
    },
};
</script>

<style lang="scss">
.content-list {
    overflow: hidden;
    background: transparent !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
}
.container-form {
    max-width: 60%;
}
.content-list-dialog {
    overflow: hidden;

    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }
}
.hint-text {
    font-size: 12px;
    color: grey;
}
</style>

<template>
    <div class="admin-competition full-height full-width">
        <div class="dialog-error error px-4" :class="{ active: error }">
            <div class="text d-flex align-center">
                <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                <b class="white--text">{{ errorMessage }}</b>
            </div>

            <v-btn x-small icon fab color="white" @click="error = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>

        <div class="overflow-hidden full-height full-width p-relative">
            <template v-if="loading">
                <v-container fluid class="pa-6 full-height full-width">
                    <div class="full-height full-width d-flex align-center justify-center">
                        <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-container>
            </template>

            <template v-else>
                <div class="p-absolute top zindex-10 full-width">
                    <v-container
                        fluid
                        class="pa-6 page-header"
                        :class="{ scrolled: scrolled || $vuetify.breakpoint.xsOnly }"
                    >
                        <v-row>
                            <v-col class="d-flex align-center justify-space-between" cols="12">
                                <h2>Rooms</h2>

                                <div class="action-buttons">
                                    <v-btn
                                        class="ml-3"
                                        :class="{ grey: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="primary"
                                        small
                                        @click="create"
                                    >
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <v-icon color="dark" small>mdi-plus</v-icon>
                                        </template>

                                        <template v-else> <v-icon small>mdi-plus</v-icon>Create Room</template>
                                    </v-btn>
                                    <v-btn
                                        class="ml-3"
                                        :class="{ grey: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="grey"
                                        small
                                        @click="close"
                                    >
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <v-icon color="dark" small>mdi-close</v-icon>
                                        </template>

                                        <template v-else> <v-icon small>mdi-close</v-icon>Close</template>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>

                <div class="overflow-y-scroll full-height full-width">
                    <div class="spacer"></div>
                    <v-container fluid class="pa-6">
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="2" v-for="room in rooms" :key="room._id">
                                <basic-tile
                                    :item="room"
                                    @click="
                                        $router.push({
                                            name: 'admin.competition-room',
                                            params: { id: $route.params.id, room: room._id },
                                        })
                                    "
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </template>
        </div>

        <v-snackbar v-model="snackbar" timeout="3000" :color="snackbarColor" top>
            <span class="full-width text-center d-block">{{ snackbarText }}</span>
        </v-snackbar>

        <ArticleCreateDialog
            v-model="createArticleObject"
            ref="articleCreateDialog"
            type="Room"
            @confirm="confirmRoom"
        ></ArticleCreateDialog>
    </div>
</template>

<script>
// Services
import Room from "@/modules/app-module/room";

// Components
import ArticleCreateDialog from "@/components/dialogs/article-create-dialog.vue";
import BasicTile from "@/components/global/basic-tile.vue";

// Packages
import _ from "lodash";

export default {
    name: "admin-rooms",

    meta: {
        title: "Rooms",
    },

    components: {
        BasicTile,
        ArticleCreateDialog,
    },

    data() {
        return {
            createArticleObject: null,
            error: false,
            scrolled: false,
            errorMessage: "",
            loading: false,
            saving: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: "",
            rooms: [],
        };
    },

    computed: {
        valid() {
            return true;
        },
    },

    methods: {
        create() {
            this.$refs.articleCreateDialog.open();
        },
        async confirmRoom(data) {
            this.$refs.articleCreateDialog.close();

            const contentType = "room";

            let created = null;
            const payload = {
                ...data,
                realms: [this.$app.liveRealm],
            };

            let entryQuery = {
                _type: "article",
                definition: "entry",
                status: {
                    $in: ["active", "draft"],
                },
                $or: [
                    {
                        "data.competition": payload.data.competition,
                    },
                    {
                        "data.competition": `ObjectId(${payload.data.competition})`,
                    },
                    {
                        "data.competition._id": payload.data.competition,
                    },
                    {
                        "data.competition._id": `ObjectId(${payload.data.competition})`,
                    },
                ],
            };

            const entries = await this.$fluro.api.post("/content/_query", entryQuery).then(({ data }) => data);
            payload.data.entries = entries;
            //Due to an award being an image it is already uploaded, just need to modify its definition

            created = await this.$fluro.api.post(`/content/${contentType}`, payload).then(({ data }) => data);

            console.log("Created", created);
            await this.init();
        },
        hasImage(competition) {
            let image = competition?.data?.image;

            return image?._id || image;
        },
        image(entry) {
            return entry?.data?.image;
        },
        async init() {
            this.loading = true;

            try {
                let query = {
                    _type: "article",
                    definition: "room",
                    status: {
                        $in: ["active"],
                    },
                    $or: [
                        {
                            "data.competition": this.$route.params.id,
                        },
                        {
                            "data.competition": `ObjectId(${this.$route.params.id})`,
                        },
                        {
                            "data.competition._id": this.$route.params.id,
                        },
                        {
                            "data.competition._id": `ObjectId(${this.$route.params.id})`,
                        },
                    ],
                };

                let rooms = await Room.query(query).then(({ data }) => data);

                this.rooms = rooms;

                console.log("ROOMS", this.rooms);
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }

            this.loading = false;
        },
        close() {
            this.$router.push({ name: "admin.competition", params: { id: this.$route.params.id } });
        },
        handleScroll(e) {
            let scrollTop = e.target.scrollTop;

            if (scrollTop > 30) {
                this.scrolled = true;
            } else {
                this.scrolled = false;
            }
        },
    },

    async mounted() {
        await this.init();

        this.$nextTick(() => {
            const scrollContainer = document.querySelector(".overflow-y-scroll");
            scrollContainer.addEventListener("scroll", this.handleScroll);
        });
    },

    destroyed() {
        const scrollContainer = document.querySelector(".overflow-y-scroll");
        if (scrollContainer) scrollContainer.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style lang="scss"></style>
